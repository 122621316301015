<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="!loading">
          <b-container>
            <b-row>
              <b-col sm="12" md="7">
                <p>
                  <b-link class="mr-3" :to="{ name: 'ImpactInitiatives' }">all initiatives</b-link>
                  <b-button class="mr-3" v-if="permission.edit && step.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'impactinitiative' }}">Edit</b-button>
                  <b-button class="mr-3" v-if="permission.addObligation && step.edit" variant="outline-primary" @click="addObligation()">New obligation</b-button>
                  <delete-standard
                    v-if="permission.delete && step.delete"
                    :id="item.id"
                    model="impactinitiative"
                  ></delete-standard>
                  <b-link class="mr-3" :to="{ name: 'ImpactInitiativeLog', params: { id: item.id }}">history</b-link>
                </p>
                <h2>
                  {{item.name}}
                </h2>
                <div class="mb-2">
                    {{item.country}} | {{item.impactstatus.name}}
                </div>
                <div class="mb-2" style="font-size: 1.2em; font-weight: 500">
                  <new-lines :content="item.headline"></new-lines>
                </div>
                <div v-html="item.description"></div>
                <div><small>{{item.created_by}} created on {{moment(item.created_at).format('YYYY-MM-DD')}}</small></div>
                <div><small>{{item.updated_by}} last updated on {{moment(item.updated_at).format('YYYY-MM-DD')}}</small></div>
                <div v-if="item.obligations.length > 0">
                  <h4 class="mt-5">Obligations</h4>
                  <b-button class="mr-3" v-if="permission.addObligation && step.edit" variant="outline-primary" @click="addObligation()">New obligation</b-button>
                  <div class="my-4" v-for="obligation in item.obligations"  :key="'obligation-'+ obligation.id">
                    <div style="font-size: 1.25em" v-if="obligation.obligationtype"><b-badge :variant="badgeVariant(obligation.obligationtype.name)">{{obligation.obligationtype.name}}</b-badge></div>
                    <h5><b-link class="mr-3" :to="{ name: 'ImpactObligation', params: { id: obligation.id }}">{{obligation.name}}</b-link> <b-button class="mr-3" v-if="permission.addObligation" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: obligation.id, model: 'impactobligation' }}">Edit</b-button></h5>
                    <div><strong>description</strong></div>
                    <div class="mb-2"><new-lines :content="obligation.description"></new-lines></div>
                    <div><strong>context / entity scope</strong></div>
                    <div class="mb-2"><new-lines :content="obligation.context"></new-lines></div>
                    <div v-if="obligation.dates.length > 0">
                      <div v-for="date in obligation.dates" :key="'date' + date.id">
                        <strong>{{date.date}}</strong> - {{date.name}}
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <h4>Workflow</h4>
                <div>status: <strong>{{step.name}}</strong></div>
                <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
                <div>
                  <span v-if="!savingStep && permission.edit">
                    <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                    <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                  </span>
                  <span v-if="savingStep">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                  </span>
                </div>
                <h4 class="mt-5">Experts</h4>
                <div v-for="expert in item.impactexperts" :key="'expert'+expert.id" style="font-size: 1.2em">
                  <router-link :to="{ name: 'ImpactExpert', params: { id: expert.id }}">{{expert.name}}</router-link>
                </div>
                <div v-if="item.issued || item.effective || item.first_reporting">
                  <h4 class="mt-5">Dates</h4>
                  <div v-if="item.issued">Issued: {{item.issued}}</div>
                  <div v-if="item.effective">Effective: {{item.effective}}</div>
                  <div v-if="item.first_reporting">First reporting: {{item.first_reporting}}</div>
                </div>
                <div v-if="item.reportingfrequency">
                  <div v-if="item.reportingfrequency.id != 1">
                    <h4 class="mt-5">Reporting frequency</h4>
                    <div>{{item.reportingfrequency.name}}</div>
                  </div>
                </div>
                <div v-if="item.report_type">
                  <h4 class="mt-5">Report type</h4>
                  <div>{{item.report_type}}</div>
                </div>
                <div v-if="item.reportingframeworks.length > 0">
                  <h4 class="mt-5">Reporting frameworks</h4>
                  <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.assurance">
                  <div v-if="item.assurance.id != 1">
                    <h4 class="mt-5">Assurance</h4>
                    <div>{{item.assurance.name}}</div>
                  </div>
                </div>
                <div v-if="item.mandatory || item.optional || item.comply_explain">
                  <h4 class="mt-5">Reporting obligation</h4>
                  <div>
                    <span v-if="item.mandatory">mandatory | </span>
                    <span v-if="item.optional">optional | </span>
                    <span v-if="item.comply_explain">comply or explain</span>
                  </div>
                </div>
                <h4 class="mt-5">Topics</h4>
                <div v-for="topic in item.impacttopics" :key="'topic'+topic.id" style="font-size: 1.2em">
                  <router-link :to="{ name: 'ImpactTopic', params: { id: topic.id }}">{{topic.name}}</router-link>
                </div>
                <div v-if="item.regulators.length > 0">
                  <h4 class="mt-5">Regulators</h4>
                  <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
                    <strong>{{row.name}}</strong><br />{{row.comments}}
                  </div>
                </div>
                <div v-if="item.entities.length > 0">
                  <h4 class="mt-5">Entities</h4>
                  <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.events.length > 0">
                  <h4 class="mt-5">Events</h4>
                  <div v-for="event in item.events" :key="'event'+event.id" class="mb-3">
                    <router-link :to="{ name: 'Event', params: { id: event.id }}"><strong>{{moment(event.date).format('YYYY-MM-DD')}}</strong> - {{event.name}}</router-link>
                  </div>
                </div>
                <div v-if="item.regulations.length > 0">
                  <h4 class="mt-5">Regulations</h4>
                  <div v-for="row in item.regulations" :key="'regulation'+row.id" class="mb-3">
                    <router-link :to="{ name: 'RegDecompWorkspace', params: { id: row.id }}">{{row.name_short}}</router-link>
                  </div>
                </div>
                <div v-if="item.articles.length > 0">
                  <h4 class="mt-5">Insights</h4>
                  <div v-for="row in item.articles" :key="'article'+row.id" class="mb-3">
                    <router-link :to="{ name: 'Article', params: { id: row.id }}">{{row.title}}</router-link>
                  </div>
                </div>
                <div v-if="item.publications.length > 0">
                  <h4 class="mt-5">News</h4>
                  <div v-for="row in item.publications" :key="'newsitem'+row.id" class="mb-3">
                    <router-link :to="{ name: 'NewsItem', params: { id: row.id }}">{{row.name}}</router-link>
                  </div>
                </div>
                <div v-if="item.impactinitiativeslinks.length > 0">
                  <h4 class="mt-5">External links</h4>
                  <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
                    <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import DeleteStandard from '@/components/DeleteStandard'
import moment from 'moment'
import NewLines from '@/components/NewLines.vue'
import workflow from '@/workflows/initiative'

export default {
  name: 'ImpactInitiative',
  components: {
    DeleteStandard,
    NewLines
  },
  computed: {
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('initiativeWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).createAny('impactinitiative').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('initiativeWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).createAny('impactinitiative').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact initiative', action: 'open impact initiative', model: 'impactinitiative', model_id: this.$route.params.id })
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('impactinitiative').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('impactinitiative').granted
    this.permission.addObligation = ac.can(this.user.acgroups).createAny('impactobligation').granted
    this.load()
  },
  data () {
    return {
      badgeVariant: function (type) {
        let res = 'primary'
        if (type) {
          if (type.toLowerCase() === 'obligation') {
            res = 'danger'
          }
          if (type.toLowerCase() === 'entity') {
            res = 'success'
          }
          if (type.toLowerCase() === 'context') {
            res = 'info'
          }
        }
        return res
      },
      item: null,
      loading: true,
      moment: moment,
      permission: {
        delete: false,
        edit: false
      },
      savingStep: false
    }
  },
  methods: {
    addObligation: async function () {
      try {
        const params = {
          body: {
            impactinitiative_id: this.$route.params.id,
            username: this.user.username
          }
        }
        const obligation = await this.$Amplify.API.put('cosmos', '/impact/initiative/obligation', params)
        this.$router.push({ name: 'FormEdit', params: { id: obligation.id, model: 'impactobligation' } })
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/impact/initiative/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: id,
            workflow_created_at: moment().toISOString(),
            workflow_created_by: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/impactinitiative/${this.item.id}`, params)
        this.item.workflow_id = id
        this.item.workflow_created_by = params.body.workflow_created_by
        this.item.workflow_created_at = params.body.workflow_created_at
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>

</style>
